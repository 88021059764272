/**診察予定表 */
import React, { useState } from 'react';
import '../../styles/components/slidecard.scss';
import circle from '../icons/table__circle.svg';
import rest from '../icons/table__rest.svg';
import line from '../icons/table__line.svg';

const SlideCard = () => {
  const [isActive, setIsActive] = useState(false);

  const handleSlide = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`slide-card ${isActive ? 'is-active' : ''}`}>
      <div 
        className="slide-card__left"
        onClick={handleSlide}
      >
        <p>診察予定表</p>
      </div>
      <div 
        className={`slide-card__right ${isActive ? 'is-active' : ''}`}
      >
        <div className="reception">
            <div className="reception__phone">
                <p>ご予約・お問い合わせ</p>
                <div className="reception__phone__number">
                    <p>027-372-1231<span>（医科直通）</span></p>
                    <p>027-395-6677<span>（歯科直通）</span></p>
                </div>

            </div>
            <div className="reception__info">
                <p>歯科では、お電話・LINEよる<span>「 予約制 」</span>です。</p>
            </div>
            <div className="reception__time">
                <div className="reception__time__box">
                    <p className="reception__time__box--blue">医科受付時間</p>
                    <p className="reception__time__box--blue">｜</p>
                    <p>9:00 〜12:00</p>
                    <p>15:00 〜17:00</p>
                </div>
                <div className="reception__time__box">
                    <p className="reception__time__box--blue">歯科受付時間</p>
                    <p className="reception__time__box--blue">｜</p>
                    <p>9:00 〜12:30</p>
                    <p>14:00 〜18:30</p>
                </div>
            </div>
            <div className="reception__table">
                <table>
                    <tr>
                        <th>医科受付時間</th>
                        <th>月</th>
                        <th>火</th>
                        <th>水</th>
                        <th>木</th>
                        <th>金</th>
                        <th>土</th>
                        <th>日</th>
                        <th>祝</th>
                    </tr>
                    <tr>
                        <td>9:00 〜12:00</td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={rest}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={rest}></img></td>
                        <td><img src={rest}></img></td>
                    </tr>
                    <tr>
                        <td className="reception__table--first">15:00 〜17:00</td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={rest}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={rest}></img></td>
                        <td className="reception__table--last"><img src={rest}></img></td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <th>歯科受付時間</th>
                        <th>月</th>
                        <th>火</th>
                        <th>水</th>
                        <th>木</th>
                        <th>金</th>
                        <th>土</th>
                        <th>日</th>
                        <th>祝</th>
                    </tr>
                    <tr>
                        <td>9:00 〜12:30</td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={rest}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={rest}></img></td>
                        <td><img src={rest}></img></td>
                    </tr>
                    <tr>
                        <td className="reception__table--first">14:00 〜18:30</td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={rest}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={circle}></img></td>
                        <td><img src={rest}></img></td>
                        <td className="reception__table--last"><img src={rest}></img></td>
                    </tr>
                </table>
            </div>
        </div>
      </div>
    </div>
  );
};

export default SlideCard;
